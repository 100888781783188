<template>
  <nav
    class="z-[2] hidden flex-col justify-start gap-2 bg-white p-2 shadow-md sm:flex"
  >
    <a
      :href="config.public.editorLink"
      target="_blank"
      class="flex flex-col items-center justify-center rounded-lg px-1.5 py-0.5 hover:bg-gray-100"
    >
      <UiIcon name="edit" size="large" class="sm-max:hidden text-gray-800" />
      <div class="whitespace-nowrap">
        <span class="text-xs font-semibold leading-[1.1125rem] text-gray-800"
          >Editor</span
        >
        <UiIcon name="external-link" size="xs" class="ml-1 text-gray-500" />
      </div>
    </a>
  </nav>
  <TransitionRoot as="template" :show="sidebarOpen">
    <HeadlessDialog
      as="div"
      class="relative z-50 lg:hidden"
      @close="sidebarOpen = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="flex w-full max-w-xs flex-1">
            <div
              data-testid="mobile-nav-panel"
              class="flex grow flex-col gap-y-1 overflow-y-auto bg-white"
            >
              <UiIconButton
                icon-name="close"
                icon-color="gray"
                background-color="none"
                title="Schließen"
                size="medium"
                class="m-2"
                @click="sidebarOpen = false"
              >
              </UiIconButton>

              <nav class="flex flex-1 flex-col">
                <ul class="space-y-1 py-2 text-sm">
                  <li
                    class="px-4 py-1 font-semibold uppercase leading-normal tracking-wider text-gray-700"
                  >
                    Andere Apps
                  </li>

                  <li>
                    <a
                      :href="config.public.editorLink"
                      class="flex items-center py-1.5 pl-5 pr-4 font-medium leading-normal text-gray-800 transition-colors hover:bg-gray-200"
                    >
                      <UiIcon name="edit" size="medium" class="mr-4" />
                      Editor
                      <UiIcon
                        name="external-link"
                        size="xs"
                        class="ml-2 text-gray-500"
                      />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { UiIcon, UiIconButton } from '@dpa-id-components/dpa-shared-components'
import {
  Dialog as HeadlessDialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

const sidebarOpen = useState('sidebarOpen', () => false)
const config = useRuntimeConfig()
</script>
