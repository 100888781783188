<template>
  <header
    class="z-30 flex min-h-[4rem] items-center gap-2 bg-white px-4 py-2 shadow-default"
  >
    <UiIconButton
      class="-ml-2.5 sm:hidden"
      size="large"
      background-color="none"
      icon-color="gray"
      icon-name="menu"
      title="Menü"
      @click="sidebarOpen = true"
    />
    <NuxtLink
      :to="'/'"
      class="mr-auto my-2 w-[215px] flex items-center"
      title="Rubix Monitor"
    >
      <img
        v-if="config.public.appContext === 'production'"
        src="@/assets/icons/logo-prod.svg?url"
        alt="Monitor (Produktion)"
      />
      <img
        v-else
        src="@/assets/icons/logo-dev.svg?url"
        alt="Monitor (Entwicklung)"
      />
    </NuxtLink>
    <UiHeaderHelpMenu />
    <UiTooltip v-if="$auth.currentUser.value" content="Einstellungen">
      <NuxtLink to="/settings">
        <UiIconButton
          icon-name="settings"
          :background-color="isSettingsPageOpen ? 'blue' : 'none'"
          :icon-color="isSettingsPageOpen ? 'white' : 'gray'"
          size="medium"
          title="Einstellungen"
        />
      </NuxtLink>
    </UiTooltip>
    <HeaderUser />
  </header>
</template>

<script setup lang="ts">
import { UiIconButton } from '@dpa-id-components/dpa-shared-components'
import HeaderUser from '@/components/ui/header/HeaderUser.vue'

const { $auth } = useNuxtApp()

const config = useRuntimeConfig()

const route = useRoute()
const sidebarOpen = useState('sidebarOpen', () => false)

const isSettingsPageOpen = computed(() => route.name === 'settings')
</script>
