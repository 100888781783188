<template>
  <div class="flex h-screen flex-col items-stretch justify-stretch">
    <!-- Header -->
    <LayoutTheHeader />
    <div class="flex min-h-0 min-w-0 grow flex-col sm:flex-row">
      <LayoutNavigationBar />
      <main class="flex min-h-0 min-w-0 grow">
        <slot />
      </main>
    </div>
  </div>
</template>
